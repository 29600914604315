exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-how-we-help-tsx": () => import("./../../../src/pages/how-we-help.tsx" /* webpackChunkName: "component---src-pages-how-we-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-v-1-tsx": () => import("./../../../src/pages/landing-v1.tsx" /* webpackChunkName: "component---src-pages-landing-v-1-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-roi-tsx": () => import("./../../../src/pages/roi.tsx" /* webpackChunkName: "component---src-pages-roi-tsx" */),
  "component---src-pages-v-2-tsx": () => import("./../../../src/pages/v2.tsx" /* webpackChunkName: "component---src-pages-v-2-tsx" */)
}

